<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item
          v-for="item in itemsRoles()"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--<v-list-group
            :value="true"
            prepend-icon="mdi-account-circle"
          >
                  <template v-slot:activator>
                    <v-list-item-title>Salones</v-list-item-title>
                  </template>
                  <v-list-item
                    v-for="item in items_docente"
                    :key="item.title"
                    link
                    :to="item.link"
                  >
                    <v-list-item-action>
                      
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
      </v-list-group>-->
      </v-list>
      <template v-slot:append>
        <div style="display:flex; justify-content:flex-end;">
          <v-btn icon v-if="themeState" @click="changeTheme(false)">
            <v-icon>
              mdi-white-balance-sunny
            </v-icon>
          </v-btn>
          <v-btn icon v-else @click="changeTheme(true)">
            <v-icon>
              mdi-moon-waning-crescent
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="#353535" dense dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="mr-12 align-center">
        <v-img src="../assets/logo.svg" max-width="50"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="logout()">
        <v-icon>
          mdi-logout
        </v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api.config.js";
import { mapMutations } from "vuex";
export default {
  data: () => ({
    drawer: null,
    themeState: false,
    items_docente: [],
  }),
  computed: {},
  methods: {
    ...mapMutations(["set_cursos_profesor", "set_theme_state"]),
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },
    itemsRoles() {
      let items = [
        {
          title: "Panel",
          icon: "mdi-chart-bar",
          link: "/home",
          active: true,
          items: [],
          permision: ["admin", "student", "teacher"],
        },
        {
          title: "Registrar estudiantes",
          icon: "mdi-account-group",
          link: "/estudiantes",
          items: [],
          permision: ["admin"],
        },
        {
          title: "Registrar docentes",
          icon: "mdi-account-group",
          link: "/docentes",
          items: [],
          permision: ["admin"],
        },
        {
          title: "Registrar notas",
          icon: "mdi-notebook-check",
          link: "/alumnos",
          items: [],
          permision: ["teacher"],
        },
        {
          title: "Mis notas",
          icon: "mdi-notebook-check",
          link: "/notas-por-alumno",
          items: [],
          permision: ["student"],
        },
        {
          title: "Todas las notas",
          icon: "mdi-notebook-check",
          link: "/notas-por-grupo",
          items: [],
          permision: ["admin"],
        },
        {
          title: "Ranking Anual",
          icon: "mdi-account-star",
          link: "/ranking-por-grado",
          items: [],
          permision: ["admin", "teacher"],
        },
        {
          title: "Solicitudes Admisión",
          icon: "mdi-email-multiple",
          link: "/solicitudes-admision",
          items: [],
          permision: ["admin"],
        },
      ];

      let itemsConRoles = [];
      try {
        let datalocal = JSON.parse(localStorage.getItem("token-user"));
        let role = datalocal.rol;
        for (let i of items) {
          if (i.permision.find((r) => r == role) == role) {
            itemsConRoles.push(i);
          }
        }
      } catch (e) {
        console.log(e);
      }

      return itemsConRoles;
    },

    logout() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      try {
        let tokenlog = tok["token-user"];
        axios
          .get(api.API_PATH + "/api/logout/?token=" + tokenlog)
          .then(() => {
            localStorage.removeItem("token-user");
            this.$router.replace("/login");
            this.$vuetify.theme.dark = false;
          })
          .catch((e) => {
            console.log(e);
            localStorage.removeItem("token-user");
            this.$router.replace("/login");
          });
      } catch (err) {
        console.log(err);
      }
    },
    changeTheme(status) {
      this.themeState = !this.themeState;
      this.$vuetify.theme.dark = status;
      this.set_theme_state(this.$vuetify.theme.dark);
    },
  },
  mounted() {},
};
</script>

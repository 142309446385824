import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/solicitudes-admision",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/home",
    name: "About",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/estudiantes",
    name: "Estudiantes",
    component: () => import("../views/CreateUsers.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const r = JSON.parse(localStorage.getItem("token-user"));
      const role = r.rol;
      if (role == "admin") {
        next();
      } else {
        next("home");
      }
    },
  },
  {
    path: "/docentes",
    name: "Docentes",
    component: () => import("../views/CreateTeachers.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const r = JSON.parse(localStorage.getItem("token-user"));
      const role = r.rol;
      if (role == "admin") {
        next();
      } else {
        next("home");
      }
    },
  },
  {
    path: "/alumnos",
    name: "Alumnos",
    component: () => import("../views/Alumnos.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const r = JSON.parse(localStorage.getItem("token-user"));
      const role = r.rol;
      if (role == "admin" || role == "teacher") {
        next();
      } else {
        next("home");
      }
    },
  },
  {
    path: "/notas-por-alumno",
    name: "NotasPorAlumno",
    component: () => import("../views/NotasAlumno.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const r = JSON.parse(localStorage.getItem("token-user"));
      const role = r.rol;
      if (role == "admin" || role == "student") {
        next();
      } else {
        next("home");
      }
    },
  },
  {
    path: "/notas-por-grupo",
    name: "NotasPorGrupo",
    component: () => import("../views/AllCalifications.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const r = JSON.parse(localStorage.getItem("token-user"));
      const role = r.rol;
      if (role == "admin") {
        next();
      } else {
        next("home");
      }
    },
  },
  {
    path: "/notas-por-grupo/:idalumno",
    name: "NotasPorGrupoDetalle",
    component: () => import("../views/NotaAlumnoAdmin.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const r = JSON.parse(localStorage.getItem("token-user"));
      const role = r.rol;
      if (
        role == "admin"
      ) {
        next();
      } else {
        next("home");
      }
    },
  },
  {
    path: "/solicitudes-admision",
    name: "SolicitudesAdmision",
    component: () => import("../views/Admision.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/ranking-por-grado",
    name: "RankingPorGrado",
    component: () => import("../views/Ranking.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const r = JSON.parse(localStorage.getItem("token-user"));
      const role = r.rol;
      if (
        role == "admin" ||
        r.id == 17 ||
        r.id == 9 ||
        r.id == 4 ||
        r.id == 8 ||
        r.id == 25 ||
        r.id == 23 ||
        r.id == 14 ||
        r.id == 18 ||
        r.id == 11 ||
        r.id == 6 ||
        r.id == 16 ||
        r.id == 5 ||
        r.id == 21 ||
        r.id == 19 ||
        r.id == 15 ||
        r.id == 1 ||
        r.id == 13
      ) {
        next();
      } else {
        next("home");
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
function existToken() {
  let tok = JSON.parse(localStorage.getItem("token-user"));
  return !!tok;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (existToken()) {
      next();
    } else {
      next("login");
    }
  } else {
    next();
  }
});
export default router;

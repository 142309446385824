import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cursos_profesor: [],
    theme_state: null,
    grupo_selected: 1,
    id_user_saved: null,
  },
  mutations: {
    set_cursos_profesor(state, arr) {
      state.cursos_profesor = arr;
    },
    set_theme_state(state, val) {
      state.theme_state = val;
    },
    set_grupo_selected(state, val) {
      state.grupo_selected = val;
    },
    set_id_user_saved(state, val) {
      state.id_user_saved = val;
    },
  },
  actions: {},
  modules: {},
});

<template>
  <v-app>
    <nav-bar v-if="existToken()"/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar'
export default {
  name: 'App',
  components:{
    NavBar
  },
  data: () => ({
    //
  }),
  methods:{
    existToken(){
            let tok = JSON.parse(localStorage.getItem('token-user'));
            return !!tok;
            },
  }
};
</script>
